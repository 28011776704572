<template>
    <section class="xbts-settings">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <h3 class="card-title">Nodes</h3>
                      <p>Current node: {{currentNode}}</p>
                        <table class="table table-striped table-borderless">
                            <tbody>
                            <tr v-for="(item, idx) in network.NODES" v-bind:key="idx">
                                <td>{{item}}</td>
                                <td>
                                  <b-button v-show="currentNode !== item" @click="connect(item)" variant="primary" size="sm">Connect</b-button>
                                  <span v-show="currentNode === item" class="text-success text-uppercase">Current Connection</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-6">
                        <h3 class="card-title">Accounts</h3>
                        <table class="table table-striped table-borderless">
                            <tbody>
                            <tr v-for="(item, idx) in accounts" v-bind:key="idx">
                                <td>{{idx}}</td>
                                <td v-if="$root.account">
                                    <div v-show="$root.account.account.name !== idx">
                                        <b-button variant="danger" title="delete" size="sm" class="btn btn-danger btn-rounded btn-icon btn-sm"><i class="mdi mdi-window-close"></i></b-button>
                                    </div>
                                    <div v-show="$root.account.account.name === idx">
                                        current
                                    </div>

                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import {network} from '@/config';


export default {
    name: "Settings",
    data() {
        return {
            network: network,
        }
    },
    computed: {
        accounts() {
            return this.$store.getters['accounts/accounts'];
        },
      currentNode() {
        return this.$store.getters['network/currentNode'];
      },
    },
    methods: {
        async connect(url) {
            await this.$store.dispatch('network/connect', url);
        }
    }
}
</script>

<style scoped>

</style>
